<template>
  <layout :style="cssProps">
    <router-view />
    <ProductFruits />
    <appcues />
    <base-snackbar />
    <animation />
  </layout>
</template>

<script>
import Layout from './Layout'
import ProductFruits from './components/ProductFruits'
import Appcues from './components/Appcues'
import BaseSnackbar from '@/components/BaseSnackbar.vue'
import brandingMixin from '@/mixins/branding'
import COMPANY_BASE from '@/queries/CompanyBase.gql'
import GET_CRM_COMPANY_CONFIG from '@/queries/GetCrmCompanyConfig.gql'
import IDENTITY from '@/queries/Identity.gql'
import { shadeColor } from '@/lib/color'
import featureNames from '@/lib/featureNames'
import featureMixin from '@/mixins/feature'
import bus, { eventNames } from '@/lib/eventBus'

export default {
  components: {
    Layout,
    ProductFruits,
    Appcues,
    Animation: () => import('./components/Animation.vue'),
    BaseSnackbar
  },
  mixins: [brandingMixin, featureMixin],
  data () {
    return {
      role: { isAuthorizedFor: () => false },
      companyBaseData: {},
      // nested value to keep reactivity for provide/inject
      crmConfigData: { value: {} },
      chatInitialized: false
    }
  },
  computed: {
    cssProps () {
      return {
        '--start-gradient-color': this.gradientColors.start || this.$vuetify.theme.themes.light.primary,
        '--stop-gradient-color': this.gradientColors.end || shadeColor(this.$vuetify.theme.themes.light.primary, 90),
        '--start-gradient-color-static': this.staticGradientColors.start || this.$vuetify.theme.themes.light.primary,
        '--stop-gradient-color-static': this.staticGradientColors.end || shadeColor(this.$vuetify.theme.themes.light.primary, 90)
      }
    }
  },

  created () {
    this.featureNames = featureNames
    document.title = `${this.readablePlatformName} App`
    bus.$on(eventNames.CRM_UPDATED, () => {
      this.$apollo.queries.config.refetch()
    })
  },

  updated () {
    const chatFeature = this.$features.feature(featureNames.ZOHO_CHAT)
    chatFeature?.isActive && this.loadZohoChat(chatFeature?.config)
  },

  methods: {
    loadZohoChat (config) {
      if (this.chatInitialized) return
      try {
        const {
          widgetCode,
          scriptId
        } = config
        window.$zoho = window.$zoho || {}
        window.$zoho.salesiq = window.$zoho.salesiq || {
          widgetcode: widgetCode,
          values: {},
          ready: () => {}
        }
        const d = document
        const s = d.createElement('script')
        s.type = 'text/javascript'
        s.id = scriptId
        s.defer = true
        s.src = 'https://salesiq.zoho.eu/widget'
        const t = d.getElementsByTagName('script')[0]
        t.parentNode.insertBefore(s, t)
        this.chatInitialized = true

        // set user data
        window.$zoho.salesiq.ready = () => {
          window.$zoho.salesiq.visitor.name(this.$auth.user.name)
          window.$zoho.salesiq.visitor.email(this.$auth.user.email)
          window.$zoho.salesiq.visitor.contactnumber(this.$auth.user.phone_number)
        }
      } catch (error) {
        /* eslint-disable-next-line no-console */
        console.log('could not load zoho chat')
      }
    }
  },
  provide () {
    return {
      companyBaseData: this.companyBaseData,
      crmConfigData: this.crmConfigData,
      role: this.role
    }
  },

  apollo: {
    company: {
      query: COMPANY_BASE,
      variables () {
        return {
          id: this.$auth.user.companyId
        }
      },
      skip () {
        return this.$auth.loading || !this.$auth.isAuthenticated || !this.$auth.user.companyId
      },
      result ({ data }) {
        if (data) {
          Object.keys(data.company).forEach(key => {
            this.companyBaseData[key] = data.company[key]
          })
        }
      }
    },
    identity: {
      query: IDENTITY,
      skip () {
        return this.$auth.loading || !this.$auth.isAuthenticated || !this.$auth.user.companyId
      },
      result ({ data }) {
        this.role.userId = data?.identity.user.id
        this.role.slug = data?.identity.user.role?.slug
        this.role.name = data?.identity.user.role?.name
        this.role.permissions = data?.identity.user.role?.permissions
        this.role.isAuthorizedFor = (permission) => !this.$features.feature(this.featureNames.AUTHORIZATION)?.isActive || data?.identity.user.role?.permissions.includes(permission)
      }
    },
    config: {
      query: GET_CRM_COMPANY_CONFIG,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      },
      skip () {
        if (this.$auth.loading || !this.$auth.isAuthenticated || !this.$auth.user.companyId) {
          return true
        }
        return !this.isFeatureActive(this.featureNames.CRM)
      },
      result ({ data }) {
        if (data) {
          this.crmConfigData.value = data.config
        }
      }
    }
  }
}
</script>

<style>
.full-size-container{
  padding: 0px;
}

@media (min-width: 1904px) {
  .layout-container {
    max-width: 1185px;
  }
}

.btn-multiline{
  width: 250px;
}

.btn-multiline > span {
  width: 100%
}

.primary-gradient-background{
  background: var(--start-gradient-color);
  background: linear-gradient(40deg, var(--start-gradient-color) 0%,var(--stop-gradient-color) 100%);
}

.static-gradient-background{
  background: var(--start-gradient-color-static);
  background: linear-gradient(40deg, var(--start-gradient-color-static) 0%,var(--stop-gradient-color-static) 100%);
}

.background-clip-icon{
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.v-card, .v-expansion-panel, .v-dialog, .v-alert {
  border-radius: 15px !important;
}

.v-text-field {
  border-radius: 10px !important;
}

.expansion-panel-border{
  border: thin solid rgba(0, 0, 0, 0.12)
}

.w-full{
  width: 100%;
}

@media (max-width: 600px) {
  .w-xs-full{
    width: 100%;
  }
}

.h-full{
  height: 100%;
}
</style>
