<template>
  <div class="d-flex">
    <div
      style="position:relative"
      class="mr-8"
    >
      <v-icon
        :class="'mr-2 ' + styleIcon"
        style="position:absolute"
        :style="{left: isBigLogo ? '-25px' : isMediumLogo ? '-3px' : '0px', top: isBigLogo ? '-18px' : isMediumLogo ? '0px' : '0px' }"
        :size="iconSize"
      >
        {{ icon }}
      </v-icon>
      <span
        style="position:absolute;"
        :style="{marginTop: isBigLogo ? '2px' : isMediumLogo ? '1px' : '2px', left: isBigLogo ? '-1px' : isMediumLogo ? '1px' : '0px'}"
        :class="{[`ml-2 font-weight-bold ${styleLabel}`]: true, 'text-body-2' : isSmallLogo , 'text-h6' : isMediumLogo , 'text-h2':isBigLogo}"
      >{{ labelShort }}</span>
    </div>
    <span v-if="showLabel"><b>{{ label }}</b></span>
  </div>
</template>

<script>
import { ProductType } from '@/lib/productTypes'

const packageInfo = {
  [ProductType.LIGHT]: {
    icon: 'mdi-decagram-outline',
    color: 'gray',
    labelShort: 'L',
    styleLabel: 'grey--text',
    styleIcon: 'grey--text',
    label: 'Light'
  },
  [ProductType.BASIC]: {
    icon: 'mdi-decagram-outline',
    color: 'primary-gradient-background',
    labelShort: 'B',
    styleLabel: 'static-gradient-background background-clip-icon',
    styleIcon: 'static-gradient-background background-clip-icon',
    label: 'Basic'
  },
  [ProductType.EXPERT]: {
    icon: 'mdi-decagram',
    color: 'primary-gradient-background',
    labelShort: 'E',
    styleLabel: 'white--text',
    styleIcon: 'static-gradient-background background-clip-icon',
    label: 'Expert'
  },
  [ProductType.BEGINNER]: {
    icon: 'mdi-decagram-outline',
    color: 'gray',
    labelShort: 'B',
    styleLabel: 'grey--text',
    styleIcon: 'grey--text',
    label: 'Beginner'
  },
  [ProductType.ADVANCED]: {
    icon: 'mdi-decagram',
    color: 'primary-gradient-background',
    labelShort: 'A',
    styleLabel: 'white--text',
    styleIcon: 'static-gradient-background background-clip-icon',
    label: 'Advanced'
  }
}
export default {
  props: {
    currentPackage: {
      type: String,
      default: ProductType.LIGHT
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: 'small'
    }
  },
  data () {
    return {
      packageInfo
    }
  },
  computed: {
    isBigLogo () {
      return this.size === 'large'
    },
    isMediumLogo () {
      return this.size === 'medium'
    },
    isSmallLogo () {
      return this.size === 'small'
    },
    iconSize () {
      return this.isBigLogo ? 100 : this.isMediumLogo ? 36 : 24
    },
    styleIcon () {
      return this.packageInfo[this.currentPackage].styleIcon
    },
    styleLabel () {
      return this.packageInfo[this.currentPackage].styleLabel
    },
    icon () {
      return this.packageInfo[this.currentPackage].icon
    },
    color () {
      return this.packageInfo[this.currentPackage].color
    },
    label () {
      return this.packageInfo[this.currentPackage].label
    },
    labelShort () {
      return this.packageInfo[this.currentPackage].labelShort
    }
  }

}
</script>
