
const featureNames = Object.freeze({
  AUTHORIZATION: 'authorization',
  AI_FORGE: 'ai-forge',
  ASSIGN_LEADS: 'assign-leads',
  APP_BRANDING: 'app-branding',
  AVV: 'avv',
  BILLWERK: 'billwerk',
  COMPANY_USER: 'company-user',
  COMPANY: 'company',
  CONSULTING: 'consulting',
  CONTACTS: 'contacts',
  CONTRACT: 'contract',
  CMS: 'cms',
  CRM: 'crm',
  CONTENT_CREATOR: 'content-creator',
  DATA_PRIVACY: 'data-privacy',
  EVENT_CALENDAR: 'event-calendar',
  FEEDBACK_TICKET: 'feedback-ticket',
  HELPCENTER: 'helpcenter',
  HOPPERMATION: 'hoppermation',
  LANDINGPAGE_NAVIGATION: 'landingpage-navigation',
  LEAD: 'lead',
  LEAD_EXPORT: 'lead-export',
  LICENSE_AGREEMENT: 'license-agreement',
  MATERIAL_GUIDE: 'material-guide',
  NOTIFICATIONS: 'notifications',
  ONBOARDING_PREVIEW: 'onboarding-preview',
  PARTNER: 'partner',
  PERFORMANCE_MARKETING: 'performance-marketing',
  PLOT_VALUATION: 'lp-grundstuecksbewertung',
  POSTAL_SHIPPING: 'postal-shipping',
  REGENERATION: 'regeneration',
  STATIC_CAMPAIGN: 'static-campaign',
  STATISTIC_DASHBOARD: 'statistic-dashboard',
  SIGNUP: 'signup',
  REGISTER: 'register',
  SUPPORT_CONTACT: 'support-contact',
  VALUATION: 'valuation',
  ACQUISITION_BOOSTER: 'acquisition-booster',
  LEAD_IMPORT: 'lead-import',
  DELETE_CONTACT: 'delete-contact',
  TEXT_AI_TOPICS: 'text-ai-topics',
  ASKAI_ASSISTENT: 'askai-assistant',
  ASKAI_ASSISTENT_PREVIEW: 'askai-assistant-preview',
  ZOHO_CHAT: 'zoho-chat',
  TODO: 'todo',
  LANDINGPAGE: 'landingpage',
  DELETE_LEADS: 'delete-leads',
  CHANGE_SUBSCRIPTION: 'change-subscription',
  RETURNCALL_TOPICS: 'returncall-topics',
  I18N: 'i18n',
  IUBENDA: 'iubenda',
  TOPIC: 'topic'
})

export default featureNames
