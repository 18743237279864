/**
 * Vue I18n is an internationalization plugin for Vue.js. It easily integrates localization features.
 *
 * This file creates a VueI18n instance, and set the locale and messages options:
 *    const i18n = new VueI18n({
 *      locale: 'en', // set locale
 *      messages, // set locale messages
 *    })
 *
 * Usage:
 *
 * In Vue components, you can now use the $t method to translate your application.
 * Template example:
 *    <p>{{ $t('foo.bar') }}</p>
 *
 *    <MyComponent :label="$t('foo.bar)" />
 *
 * Script example:
 *  const label = this.$t('foo.bar')
 *
 *
 * You can change the locale by changing the `locale` property of the i18n instance:
 *    i18n.locale = 'fr'
 *
 * You can also use the $i18n property in your components to access the i18n instance. This allows
 * you to change the locale directly from within a component:
 *    this.$i18n.locale = 'fr'
 *
 * The translation messages are located in the src/i18n directory. Each language has its own file.
 *
 * For more information, check out the official Vue I18n documentation: https://kazupon.github.io/vue-i18n/
 */
import Vue, { watch } from 'vue'
import VueI18n from 'vue-i18n'
import features from '@/lib/features'
import deepMergeObjects from '@/lib/deepMerge'
import { getInstance as getAuthInstance } from '@/auth'
import IDENTITY from '@/queries/Identity.gql'
import en from './en.json'
import de from './de.json'

Vue.use(VueI18n)

// As for BOTTIMMO we currently don't have the i18n feature active,
// so we need to make sure that German is the default language.
const FALLBACK_LOCALE = 'de'

const STORAGE_KEY = 'appLocale'

/**
 * Check if i18n feature is enabled
 * @returns {boolean}
 */
function isI18nEnabled () {
  return features?.i18n?.isActive
}

/**
 * Load platform specific translations if they are configured in the i18n feature
 *
 * @param {string} language
 *
 * @returns {object}
*/
function platformsSpecificTranslation (language) {
  if (features?.i18n?.config?.languageOverrides?.[language]) {
    const fileName = features.i18n.config.languageOverrides[language]
    try {
      return require(`./${fileName}.json`)
    } catch (error) {
      return null
    }
  }
  return null
}

const messages = {
  en: platformsSpecificTranslation('en') ? deepMergeObjects(en, platformsSpecificTranslation('en')) : en,
  de: platformsSpecificTranslation('de') ? deepMergeObjects(de, platformsSpecificTranslation('de')) : de
}

const availableLocales = Object.keys(messages)

function getLocaleFromStorage () {
  return localStorage.getItem(STORAGE_KEY)
}

function saveLocaleToStorage (locale) {
  localStorage.setItem(STORAGE_KEY, locale)
}

function getBrowserLocale () {
  return window.navigator.language.split('-')[0]
}

function getFallbackLocale () {
  return isI18nEnabled() ? features.i18n.config.defaultLocale : FALLBACK_LOCALE
}

/**
 * Get the locale with which the plugin should be initialized.
 * It considers the following sources in order:
 * 1. Local storage
 * 2. Browser locale
 * 3. Fallback locale
 *
 * @returns {string} locale
 */
function getClientLocale () {
  // Even if we define a fallbackLocale on the plugin we always want to return a valid locale here
  // as we get warnings in the console otherwise.
  let locale = getFallbackLocale()

  if (isI18nEnabled()) {
    const selectedLocale = getLocaleFromStorage()
    if (selectedLocale) {
      locale = selectedLocale
    } else {
      const browserLocale = getBrowserLocale()
      const isBrowserLocaleSupported = availableLocales.includes(browserLocale)
      if (isBrowserLocaleSupported) {
        locale = browserLocale
      }
    }
  }

  return locale
}

/**
 * It fetches the user's locale from the server and sets it as the app's locale.
 * Usually the locale from the server will be the same but can change if e.g.
 * the user changes it on a different device.
 *
 * @param {Object} apolloClient
 * @param {Object} instance i18n Vue instance
 */
async function usePersistedLocale (apolloClient, instance) {
  const auth = getAuthInstance()
  watch(() => auth.isAuthenticated, async (isAuthenticated) => {
    if (isAuthenticated) {
      const result = await apolloClient.query({ query: IDENTITY })
      const { user } = result.data.identity

      if (user.appLocale && user.appLocale !== instance.locale) {
        instance.locale = user.appLocale
        saveLocaleToStorage(user.appLocale)
      }
    }
  }, { immediate: true })
}

const i18n = {
  _instance: null,

  availableLocales,

  /**
   * It initializes the i18n Vue instance (if not already initialized) and returns it.
   *
   * @param {Object} apolloClient
   * @returns {Object} i18n Vue instance
   */
  getInstance (apolloClient = null) {
    if (!this._instance) {
      this._instance = new VueI18n({
        locale: getClientLocale(),
        fallbackLocale: getFallbackLocale(),
        messages
      })
    }

    if (apolloClient) {
      usePersistedLocale(apolloClient, this._instance)
    }

    return this._instance
  },

  /**
   * It changes the locale of the i18n instance and saves it to the local storage.
   * @param {string} locale
   */
  changeLocale (locale) {
    i18n.getInstance().locale = locale
    saveLocaleToStorage(locale)
  }
}

export default i18n
