<template>
  <v-card outlined>
    <v-card-title>
      Passwort zurücksetzen
    </v-card-title>

    <v-card-text>
      Geben Sie bitte Ihre E-Mail-Adresse an.
      Wir werden Ihnen umgehend eine E-Mail senden,
      mit der Sie Ihr Passwort zurücksetzen können.
    </v-card-text>

    <v-expand-transition>
      <v-alert
        v-if="error"
        type="error"
        dense
        tile
        text
      >
        {{ getErrorMessage(error) }}
      </v-alert>
    </v-expand-transition>

    <v-card-text>
      <v-form @submit="submit">
        <v-text-field
          v-model.trim="email"
          label="E-Mail-Adresse"
          type="email"
          outlined
        />

        <v-card-actions class="px-0">
          <router-link :to="{ name: 'login' }">
            Zurück zum Login
          </router-link>
          <v-spacer />
          <loading-button
            color="primary"
            type="submit"
            :loading="submitting"
          >
            absenden
          </loading-button>
        </v-card-actions>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import LoadingButton from '@/components/loading-button'
import { email } from '@/lib/validation'
import REQUEST_PASSWORD_RESET from '@/queries/RequestPasswordReset.gql'

const errorMessages = {
  'invalid-email': 'Bitte geben Sie eine gültige E-Mail-Adresse an.',
  'empty-email': 'Bitte geben Sie Ihre E-Mail-Adresse an.',
  'unknown-error': 'Es ist ein unbekannter Fehler aufgetreten. Bitte versuchen Sie es später erneut.'
}

export default {
  components: {
    LoadingButton
  },
  data: () => ({
    email: '',
    error: null,
    submitting: false
  }),
  // request is without authtoken, therefore use other apollo client
  apollo: {
    $client: 'public'
  },
  methods: {
    async submit (evt) {
      evt.preventDefault()

      this.error = null

      if (!this.email) {
        this.error = 'empty-email'
        return
      }

      if (email(this.email) !== true) {
        this.error = 'invalid-email'
        return
      }

      this.submitting = true

      try {
        const { data } = await this.$apollo.mutate({
          mutation: REQUEST_PASSWORD_RESET,
          variables: {
            input: {
              email: this.email
            }
          }
        })
        this.submitting = false

        const { errorCode } = data.sendResetOrVerificationMail

        // Pretend to send reset pw mail, when user was not found to avoid user sniffing
        if (errorCode && errorCode !== 'user-not-found') {
          this.error = errorCode
          return
        }

        this.$emit('submitted', { email: this.email })
      } catch (error) {
        this.error = 'unknown-error'
        this.submitting = false
      }

      this.submitting = false
    },
    getErrorMessage: code => errorMessages[code]
  }
}
</script>
