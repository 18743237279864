import { authGuard } from '@/guards/auth'
import { getProductPackageQuery } from '@/modules/productPackages/lib/productQuery'
export const hasRequiredPaymentData = (to, from, next) => {
  authGuard(() => {
    const productPackage = getProductPackageQuery()
    if (!productPackage) {
      return next({ name: 'product-packages' })
    }

    return next()
  })
}
