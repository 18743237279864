import { getInstance } from '../auth/index'
import { getProductPackageQuery, removeProductPackageQuery } from '@/modules/productPackages/lib/productQuery'
import features from '@/lib/features'
const authGuard = (fn) => {
  const authService = getInstance()

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn()
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch('loading', loading => {
    if (loading === false) {
      return fn()
    }
  })
}

const authenticated = (to, from, next) => {
  const authService = getInstance()

  authGuard(() => {
    // If the user is authenticated, continue with the route
    if (authService.isAuthenticated) {
      return next()
    }
    // Otherwise redirect to home
    authService.rememberLocationBeforeLogin()
    return next({ name: 'login' })
  })
}

const publicOnly = (to, from, next) => {
  const authService = getInstance()

  authGuard(() => {
    // If the user is not authenticated, continue with the route
    if (!authService.isAuthenticated) {
      return next()
    }

    // Otherwise redirect to dashboard
    return next({ name: 'dashboard', replace: true })
  })
}

const paymentGuard = async (to, from, next) => {
  authGuard(async () => {
    if (from.name !== 'product-packages' && isPageEnteredDirectly(from) && !isMarkeroFlowActive()) {
      removeProductPackageQuery()
      return next({ name: 'product-packages', replace: true })
    }

    const isAbortPaymentCallback = to.fullPath.includes('trigger=Abort')
    if (isAbortPaymentCallback) {
      return next({ name: 'payment', query: getProductPackageQuery(), replace: true })
    }

    return next()
  })
}

const companyBillingGuard = async (to, from, next) => {
  authGuard(() => {
    const markeroFlow = isMarkeroFlowActive()

    if (from.name !== 'product-packages' && isPageEnteredDirectly(from) && !markeroFlow) {
      removeProductPackageQuery()
      return next({ name: 'product-packages', replace: true })
    }

    if (markeroFlow) {
      sessionStorage.removeItem('markeroRegistrationFlow')
    }

    return next()
  })
}

const registerGuard = async (to, from, next) => {
  authGuard(async () => {
    const authService = getInstance()
    const productPackage = getProductPackageQuery()
    const directEntry = isPageEnteredDirectly(from)

    if (directEntry) {
      removeProductPackageQuery()
      return next({ name: 'dashboard', replace: true })
    }

    if (authService.isAuthenticated) {
      if (productPackage) {
        return next({ name: 'payment', query: productPackage, replace: true })
      }

      removeProductPackageQuery()
      return next({ name: 'product-packages', replace: true })
    }

    return next()
  })
}

const markeroRegistrationProcess = async (to, from, next) => {
  const authService = getInstance()

  authGuard(async () => {
    if (features.appBranding.config.platform !== 'markero') {
      return next()
    }

    const productPackage = getProductPackageQuery()
    if (!authService.isAuthenticated) {
      return next()
    }

    const markeroFlow = isMarkeroFlowActive()
    const isRegisterRoute = to.name === 'register'

    if (productPackage && (isRegisterRoute || (!from.name && markeroFlow))) {
      return next({ name: 'payment', query: productPackage, replace: true })
    }

    return next()
  })
}

const isMarkeroFlowActive = () => JSON.parse(sessionStorage.getItem('markeroRegistrationFlow') || 'false')
const isPageEnteredDirectly = (from) => !from.name

export {
  authGuard,
  authenticated,
  publicOnly,
  markeroRegistrationProcess,
  companyBillingGuard,
  registerGuard,
  paymentGuard
}
